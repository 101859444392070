import React, { lazy, Suspense, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from "./Home/Home";
import PrivateRoute from "./PrivateRoute";
import LoginError from "./ErrorPage/LoginError";
import { DataContext } from "../utilities/DataContext";
// import SearchScreen from "./Search/Search";
// import FinisherScreen from "./Finisher/Finisher";
// import RegistrationScreen from "./Registration/Registration";
// import BusinessFormPage from "./BusinessForm/BusinessForm";
// import BusinessPage from "./BusinessPage/BusinessPage";
// import AdsMainPage from "./Ads/AdsMainPage";
// import AdPreviewPage from "./AdPreviewPage/AdPreviewPage";
// import AdsList from "./AdsList/AdsList";
// import ChangePassword from "./ChangePassword/ChangePassword";
// import SuccessPage from "./SuccessPage/SuccessPage";
// import ErrorPage from '../screens/ErrorPage/ErrorPage'
// import UserSelectionPage from "./UserPage/UserSelectionPage";
// import PrivateUserRegistration from "./PrivateUserRegistration/PrivateUserRegistration";
// import Profile from "./UserProfile/Profile";
// import Messaging from "./Messaging/Messaging";
// import RegistrationSuccess from "./PrivateUserRegistration/RegistrationSuccess";
import ScrollToTop from "../Components/ScrollToTop";

// const HomeScreen = lazy(() => import('./Home/Home'));
const AuthError = lazy(() => import('./ErrorPage/AuthErrorPage'));
const SearchScreen = lazy(() => import('./Search/Search'));
const FinisherScreen = lazy(() => import('./Finisher/Finisher'));
const RegistrationScreen = lazy(() => import('./Registration/Registration'));
const BusinessFormPage = lazy(() => import('./BusinessForm/BusinessForm'));
const BusinessPage = lazy(() => import('./BusinessPage/BusinessPage'));
const AdsMainPage = lazy(() => import('./Ads/AdsMainPage'));
const AdPreviewPage = lazy(() => import('./AdPreviewPage/AdPreviewPage'));
const DashBoard = lazy(() => import('./DashBoard/DashBoard'));
const AdsList = lazy(() => import('./AdsList/AdsList'));
const ChangePassword = lazy(() => import('./ChangePassword/ChangePassword'));
const SuccessPage = lazy(() => import('./SuccessPage/SuccessPage'));
const ErrorPage = lazy(() => import('../screens/ErrorPage/ErrorPage'));
const UserSelectionPage = lazy(() => import('./UserPage/UserSelectionPage'));
const PrivateUserRegistration = lazy(() => import('./PrivateUserRegistration/PrivateUserRegistration'));
const Profile = lazy(() => import('./UserProfile/Profile'));
const Messaging = lazy(() => import('./Messaging/Messaging'));
const RegistrationSuccess = lazy(() => import('./PrivateUserRegistration/RegistrationSuccess'));
// const ScrollToTop = lazy(() => import('../Components/ScrollToTop'));


const MainNavigator = () => {
    const { isLoggedIn } = useContext(DataContext);

    useEffect(() => {
        console.log('isLoggedIn', isLoggedIn)
    }, [isLoggedIn])
    return (
        <Router>
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route index element={<HomeScreen />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/registrationtypeselection" element={<UserSelectionPage />} />
                    <Route path="/registration" element={<RegistrationScreen />} />
                    <Route path="/sichere-meine-unternehmensseite-auf-campervanspot" element={<RegistrationScreen />} />
                    <Route path="/private_user_registration" element={<PrivateUserRegistration />} />
                    <Route path="/businesspage/:companyName" element={<BusinessPage />} />
                    {/* <Route path="/businessform" element={<BusinessFormPage />} /> */}
                    <Route path="/businessform" element={<PrivateRoute element={<BusinessFormPage />} redirectTo="/login" requiredRole="business_user" message='businessform' />} />
                    <Route path="/search" element={<SearchScreen />} />
                    <Route path="/businesses" element={<FinisherScreen />} />
                    {/* <Route path="/adcreate" element={<AdsMainPage />} /> */}
                    <Route path="/adcreate" element={<PrivateRoute element={<AdsMainPage />} redirectTo="/login" message='adcreate' />} />
                    <Route path="/ad/:adId" element={<AdPreviewPage />} />
                    {/* <Route path="/dashboard" element={<DashBoard />} /> */}
                    <Route path="/dashboard" element={<PrivateRoute element={<DashBoard />} redirectTo="/login" message='dashboard' />} />
                    {/* <Route path="/adslist" element={<AdsList />} /> */}
                    <Route path="/adslist" element={<PrivateRoute element={<AdsList />} redirectTo="/login" message='adslist' />} />
                    <Route path="/login" element={<LoginError />} />
                    <Route path="/auth-error" element={<AuthError />} />
                    <Route path="/changepassword" element={<PrivateRoute element={<ChangePassword />} redirectTo="/login" message='changepassword' />} />
                    {/* <Route path="/successpage" element={<SuccessPage />} /> */}
                    <Route path="/successpage" element={<PrivateRoute element={<SuccessPage />} redirectTo="/login" message='successpage' />} />
                    {/* <Route path="/profile" element={<Profile />} /> */}
                    <Route path="/profile" element={<PrivateRoute element={<Profile />} redirectTo="/login" requiredRole="private_user" message='profile' />} />
                    {/* <Route path="/chat" element={<Messaging />} /> */}
                    <Route path="/chat" element={<PrivateRoute element={<Messaging />} redirectTo="/login" message='chat' />} />
                    <Route path="/registration_success" element={<PrivateRoute element={<RegistrationSuccess />} redirectTo="/login" message='registration_success' />} />
                    {/* <Route path="/registration_success" element={<RegistrationSuccess />} /> */}
                    <Route path='*' element={<ErrorPage />} />
                    {/* Catch-all route */}
                </Routes>
            </Suspense>
        </Router >
    );
}

export default MainNavigator;